import { Button } from '@almbrand/button';
import { Container } from '@almbrand/container';
import { Grid, GridCell } from '@almbrand/grid';
import { Header as HeaderComponentLibrary } from '@almbrand/header';
import classNames from 'classnames';
import { PageNames } from 'constants/pageNames';
import { usePageParam } from 'hooks/usePageParam';
import { useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import style from './Header.module.scss';
import codanLogo from './assets/CodanLogo.svg';

export type HeaderLink = {
	action: () => void;
	title: string;
};

export interface HeaderProps {
	onLogoutClick: () => void;
	navLinks: HeaderLink[];
	logoLink: string;
	logoUrl: string;
	loginUrl: Link;
	loginIcon: Image;
	loginLabelText: string;
}

export const Header: React.FC<HeaderProps> = ({
	onLogoutClick,
	navLinks,
	logoLink,
	logoUrl,
	loginUrl,
	loginIcon,
	loginLabelText,
}) => {
	const { setPageParam } = usePageParam();
	const containerRef = useRef<HTMLDivElement>(null);

	//const addClass = useToggleClassOnScoll(10);

	const dispatch = useAppDispatch();

	//dispatch(setGlobalCssClassState(addClass));

	const isUserLoggedIn = useAppSelector((state) => state.customerParty?.data?.isUserLoggedIn);
	const defaultLoginText = loginLabelText.trim() !== '' ? loginLabelText : 'Log ind';
	const buttonText = isUserLoggedIn ? 'Log ud' : defaultLoginText;

	const onClickHandler = () => {
		isUserLoggedIn ? onLogoutClick() : setPageParam(PageNames.LoginPage);
	};

	return (
		<Container
			backgroundColor='bg-white'
			desktopWidth='fullWidth'
			mobileWidth='fullWidth'
			tabletWidth='fullWidth'
			align='none'
			noPadding
		>
			<Container
				backgroundColor='bg-white'
				desktopWidth='1600'
				mobileWidth='fullWidth'
				tabletWidth='fullWidth'
				noPadding
				className={classNames(style.Header)}
			>
				<Grid ref={containerRef}>
					<GridCell
						desktopWidth='12col'
						tabletWidth='12col'
						mobileWidth='12col'
						nogutter
					>
						<HeaderComponentLibrary
							logoUrl={logoUrl ?? codanLogo}
							logoLink={logoLink}
							navigationLinks={navLinks}
							renderChildren={(link: HeaderLink) => (
								<Button
									onClick={() => link.action()}
									children={link.title}
									key={link.title}
									buttonType='link'
									size='small'
								/>
							)}
							buttonProps={{
								children: buttonText,
								onClick: () => onClickHandler(),
								...(isUserLoggedIn ? {} : { linkButtonSettings: { href: loginUrl?.url ?? '#' } }),
								icon: {
									src: loginIcon?.url ?? '',
									altText: 'Login icon',
								},
							}}
						/>
					</GridCell>
				</Grid>
			</Container>
		</Container>
	);
};
