import { Checkbox } from '@almbrand/checkbox';
import { Container } from '@almbrand/container';
import { Grid, GridCell } from '@almbrand/grid';
import { TileCheckbox } from '@almbrand/tilecheckbox';
import { RichText } from 'components/1-atoms';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAppSelector } from 'store/hooks';
import ReadOnlyCheckboxList from '../ReadOnlyCheckboxList/ReadOnlyCheckboxList';
import styles from './CheckBoxList.module.scss';

export interface CheckBoxItem extends WizardBaseListItem {
	defaultSelected: boolean;
	standalone: boolean;
	text: string;
	iconUrl: string;
	renderAsTileCheckbox: boolean;
}

export declare interface CheckBoxListProps extends WizardBaseList {
	label: string;
	value: CheckBoxItem;
	allowCustomSelectItem: boolean;
	customSelectItemTag: string;
	customSelectItemLabel: string;
	selectItems: CheckBoxItem[];
	imageMapSvgMarkup: string;
	checkboxOtherErrorMessage: string;
	register: any;
	hasError?: boolean;
	pageName?: string;
}

export const CheckBoxList: React.FC<CheckBoxListProps> = ({
	fieldName = '',
	selectItems,
	inputId,
	required,
	label,
	validationErrorFormatText,
	hasError,
	register,
	pageName: relatedPagePath,
}) => {
	const { setValue } = useFormContext();
	const { onChange, name, ref } = register(fieldName, { required });

	const [checkedItems, setCheckedItems] = useState<{ label: string; value: string }[]>([]);

	const displayAsTile = useMemo(() => selectItems.some((item) => item.renderAsTileCheckbox), [selectItems]);

	const customerPartyData = useAppSelector((store) => store.customerParty?.data || {});

	const isReadOnly = Boolean(customerPartyData[`${fieldName}_readonly`]);
	const readOnlyFieldData = isReadOnly ? customerPartyData[fieldName] : null;
	const readonlyFieldPagePath = isReadOnly ? relatedPagePath : null;

	const fieldWatch = useAppSelector((store) => store.claimForm[fieldName]);

	useEffect(() => {
		const checkBoxList = fieldWatch?.data;
		if (!checkBoxList) return;
		if (JSON.stringify(checkedItems) !== JSON.stringify(checkBoxList)) {
			setCheckedItems(checkBoxList);
			setValue(fieldName, checkBoxList);
		}
	}, [fieldWatch]);

	const groupByTag = () => {
		return selectItems.reduce(
			(groups, item) => {
				const groupKey = item.text || 'ungrouped';
				if (!groups[groupKey]) {
					groups[groupKey] = [];
				}
				groups[groupKey].push(item);
				return groups;
			},
			{} as { [key: string]: CheckBoxItem[] }
		);
	};

	const groupedItems = useMemo(() => groupByTag(), [selectItems]);

	const handleChange = useCallback(
		(checked: boolean, checkBoxItem: CheckBoxItem) => {
			setCheckedItems((prevState) => {
				const newState = checked
					? [...prevState, { label: checkBoxItem.label, value: checkBoxItem.value }]
					: prevState.filter((item) => item.value !== checkBoxItem.value);

				onChange({
					target: {
						name: name,
						value: newState,
					},
				});

				return newState;
			});
		},
		[name, onChange]
	);

	return (
		<>
			{displayAsTile && !isReadOnly ? (
				<Container
					desktopWidth='996'
					tabletWidth='996'
					mobileWidth='744'
					noPadding
					className={styles.CheckBoxList__container}
				>
					{groupedItems &&
						Object.entries(groupedItems).map(([group, items], groupIndex) => (
							<Grid
								container
								key={`${group}-${groupIndex}`}
							>
								<GridCell
									desktopWidth='12col'
									className={styles.CheckBoxList__groupWrapper}
								>
									{group !== 'ungrouped' && <RichText description={group} />}
								</GridCell>

								{items.map((checkBoxItem: CheckBoxItem, index: any) => (
									<GridCell
										textAlign='center'
										desktopWidth='3col'
										tabletWidth='4col'
										mobileWidth='6col'
										key={`${group}-item-${index}`}
									>
										<TileCheckbox
											name={name}
											value={checkBoxItem.value}
											title={checkBoxItem.label}
											onChange={(checked) => handleChange(checked, checkBoxItem)}
											checked={checkedItems.some((item) => item.value == checkBoxItem.value)}
											icon={checkBoxItem.iconUrl}
										/>
									</GridCell>
								))}
							</Grid>
						))}
				</Container>
			) : (
				<Grid container>
					{isReadOnly && readOnlyFieldData && readOnlyFieldData.length > 0 ? (
						<ReadOnlyCheckboxList
							readOnlyFieldData={readOnlyFieldData}
							inputId={inputId?.toString()}
							name={name}
							readonlyFieldPagePath={readonlyFieldPagePath ?? ''}
						/>
					) : (
						selectItems?.map((checkBoxItem, index) => (
							<GridCell
								desktopWidth={selectItems.length > 1 ? '6col' : '12col'}
								nogutter
								key={checkBoxItem.index}
							>
								<Checkbox
									name={name}
									id={inputId?.toString()}
									value={checkBoxItem.value}
									label={checkBoxItem.label}
									onChange={(e) => handleChange(e.target.checked, checkBoxItem)}
									ref={ref}
									checked={checkedItems.some((item) => item.value == checkBoxItem.value)}
								/>
							</GridCell>
						))
					)}
				</Grid>
			)}
			{hasError && <p className={styles.CheckBoxList__errorMessage}>{validationErrorFormatText}</p>}
		</>
	);
};
