import { RefObject, useEffect, useState } from 'react';

type Settings = {
	delay?: number;
	element?: RefObject<any>;
	targetClass?: string;
};

type State = {
	direction: 'UP' | 'DOWN' | undefined;
	scroll: boolean;
};

export const useScrollDirection = (settings?: Settings) => {
	const [state, setState] = useState<State>({
		direction: undefined,
		scroll: false,
	});

	const start = {
		x: 0,
		y: 0,
	};

	useEffect(() => {
		let timeout = false; //Debouncer
		const handleWheel = (e: WheelEvent) => {
			const delta = e.deltaY;

			if (Math.abs(delta) >= (settings?.delay || 20) && !timeout) {
				timeout = true;
				setState(buildStateObject(delta));

				setTimeout(() => (timeout = false), 1000);
			}
		};

		const handleTouchStart = (e: TouchEvent) => {
			start.x = e.touches[0].pageX;
			start.y = e.touches[0].pageY;
		};

		const handleTouchEnd = (e: TouchEvent) => {
			const offset = {
				x: 0,
				y: 0,
			};

			offset.x = start.x - e.changedTouches[0].pageX;
			offset.y = start.y - e.changedTouches[0].pageY;

			setState(buildStateObject(offset.y));
		};

		window.addEventListener('wheel', handleWheel);
		window.addEventListener('touchstart', handleTouchStart);
		window.addEventListener('touchend', handleTouchEnd);

		return () => {
			window.removeEventListener('wheel', handleWheel);
			window.removeEventListener('touchstart', handleTouchStart);
			window.removeEventListener('touchend', handleTouchEnd);
		};
	}, []);

	const checkIfOverflowForAnimation = (e: Element) => {
		if (checkForUndefined(e)) return true;

		const clientHeight = e.clientHeight;
		const targetElement = e.getElementsByClassName(settings?.targetClass || 'show')[0];

		if (clientHeight === targetElement.scrollHeight) return true;

		if (targetElement.scrollTop <= 0) return true;

		if (targetElement.scrollHeight - clientHeight <= Math.round(targetElement.scrollTop)) return true;

		return false;
	};

	const buildStateObject = (offsetY: number): State => {
		return {
			direction:
				Math.abs(offsetY) < (settings?.delay || 20)
					? undefined
					: offsetY < 0 && checkIfOverflowForAnimation(settings?.element?.current)
						? 'UP'
						: checkIfOverflowForAnimation(settings?.element?.current)
							? 'DOWN'
							: undefined,
			scroll: Math.abs(offsetY) < (settings?.delay || 20) ? false : true,
		};
	};

	const checkForUndefined = (e: Element) => {
		switch (true) {
			case e === undefined:
				console.log('WARNING:: Add a custom element prop');
				return true;
			case e.getElementsByClassName(settings?.targetClass || 'show')[0] === undefined:
				console.log('WARNING:: Add a custom tagetClass prop');
				return true;
		}
	};

	return { state };
};
